$(document).on('ready turbolinks:load', function() {
  $("select[name='facility[reservation_start_type]']").on('change', function() {
    var val = $("select[name='facility[reservation_start_type]']").val();
    if (val == "month_days") {
      $(".facility_reservation_start_X_value").slideDown();
    } else {
      $(".facility_reservation_start_X_value").slideUp();
    }
  });
  $("select[name='facility[reservation_start_type]']").trigger('change');

  $("input[id='facility_cancel_enabled']").change(function() {
    if ($("input[id='facility_cancel_enabled']").prop("checked")) {
      $(".facility_cancel_list").slideDown();
    } else {
      $(".facility_cancel_list").slideUp();
    }
  })
  $("input[id='facility_cancel_enabled']").trigger('change');

  $("select[id='facility_cancel_period_type']").change(function() {
    var val = $("select[id='facility_cancel_period_type']").val();
    if (val == "before_days_hours") {
      $(".facility_cancel_X").slideDown();
    } else {
      $(".facility_cancel_X").slideUp();
    }
  })
  $("select[id='facility_cancel_period_type'").trigger('change');

  $("select[name='facility[multiple_reservation_limit_type]']").on('change', function() {
    var val = $("select[name='facility[multiple_reservation_limit_type]']").val();
    if (val == "none") {
      $(".facility_multiple_reservation_limit_number").slideUp()
    } else {
      $(".facility_multiple_reservation_limit_number").slideDown()
    }
  })
  $("select[name='facility[multiple_reservation_limit_type]']").trigger('change');

  $("select[id='facility_reservation_reception_end_type']").change(function() {
    var val = $("select[id='facility_reservation_reception_end_type']").val();
    if (val != "none") {
      $(".facility_reservation_reception_end_N").slideDown();
    } else {
      $(".facility_reservation_reception_end_N").slideUp();
    }
  })
  $("select[id='facility_reservation_reception_end_type'").trigger('change');

  $("select[name='facility[raffle_application_start_type]']").on('change', raffle_application_start_type_changed);
  $("select[name='facility[raffle_application_start_type]']").trigger('change');

  $("select[name='facility[raffle_date_type]']").on('change', raffle_date_type_changed);
  $("select[name='facility[raffle_date_type]']").trigger('change');

  $("select[name='facility[pay_limit_date_type]']").on('change', function() {
    var val = $("select[name='facility[pay_limit_date_type]']").val();
    if (val == "use_day") {
      $(".facility_pay_limit_date_X").slideUp();
    } else {
      $(".facility_pay_limit_date_X").slideDown();
    }
  })
  $("select[name='facility[pay_limit_date_type]']").trigger('change');

  $('.tooltip-container').hover(
    function() {
      $(this).find('.tooltip-content').show();
    },
    function() {
      $(this).find('.tooltip-content').hide();
    }
  );

  $('#toggle-raffle-settings').on('click', function() {
    $('.hidden-raffle-settings').slideToggle();
    var icon = document.getElementById('toggle-icon');
    if (icon.classList.contains('fa-chevron-down')) {
      icon.classList.remove('fa-chevron-down');
      icon.classList.add('fa-chevron-up');
    } else {
      icon.classList.remove('fa-chevron-up');
      icon.classList.add('fa-chevron-down');
    }
  });

  // 初期状態でスライドが表示されている場合の設定
  if ($('#toggle-icon').hasClass('fa-chevron-up')) {
    $('.hidden-raffle-settings').show();
  }

  document.body.addEventListener(
    "error",
    (event) => {
      if (!event.target) return;
      if (event.target.tagName === "IMG") {
        $(event.target).closest(".media-fit-container").toggleClass("no-img");
      }
    },
    true,
  );
});
$(document).on('turbolinks:before-cache', function() {
  $(".media-fit-container").removeClass("no-img");
});

$(window).on('pageshow', function() {
  $("input[id='facility_cancel_enabled']").trigger('change');
  $("select[id='facility_cancel_period_type']").trigger('change');
  $("select[name='facility[multiple_reservation_limit_type]']").trigger('change');

  $("select[name='facility[raffle_application_start_type]']").trigger('change');
  $("select[name='facility[raffle_date_type]']").trigger('change');
});

function raffle_application_start_type_changed() {
  var val = $("select[name='facility[raffle_application_start_type]']").val();
  if (val == "month_days") {
    $(".facility_raffle_application_start_X_value").slideDown()
  } else {
    $(".facility_raffle_application_start_X_value").slideUp()
  }
}

function raffle_date_type_changed() {
  var val = $("select[name='facility[raffle_date_type]']").val();
  if (val == "month_days") {
    $(".facility_raffle_date_Y_value").slideDown();
    $(".facility_raffle_publication_date_Z_value").slideDown();
  } else {
    $(".facility_raffle_date_Y_value").slideUp();
    $(".facility_raffle_publication_date_Z_value").slideUp();
  }

  // 2024/04/11時点ではraffle_publication_date_typeはraffle_date_typeと同じvalue値となるようにしている
  $("input[name='facility[raffle_publication_date_type]']").val(val);
  $("input[id='facility_raffle_publication_date_type_i18n']").val(facility_raffle_publication_date_types[val]);
}
