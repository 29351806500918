$(document).on('ready turbolinks:load', function() {
  $("select[name='requested_setting[reservation_start_type]']").on('change', function() {
    var val = $("select[name='requested_setting[reservation_start_type]']").val();
    if (val == "month_days") {
      $(".requested_setting_reservation_start_X_value").slideDown();
    } else {
      $(".requested_setting_reservation_start_X_value").slideUp();
    }
  });
  $("select[name='requested_setting[reservation_start_type]']").trigger('change');
});
